import { ITMCCaptureType } from "@feature/itmc/types";
import { SxProps, Theme, keyframes, styled } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useTimeout } from "react-timers-hooks";

const maxRecordTime = 10; // record time in seconds

const recordSpin = keyframes({
	from: {
		strokeDashoffset: 289.03,
		"--itmc-capture-rotation-button": "0%",
	},
	to: {
		strokeDashoffset: 0,
		"--itmc-capture-rotation-button": "100%",
	},
});

const Button = styled("button", {
	shouldForwardProp: (prop) => prop !== "recording" && prop !== "captureType",
})<{ recording: boolean; captureType: ITMCCaptureType }>(({ recording, captureType }) => ({
	display: "block",
	width: "72px",
	height: "72px",
	border: "none",
	padding: "0px",
	position: "relative",
	cursor: "pointer",
	background: "none",
	"@property --itmc-capture-rotation-button": {
		syntax: "'<percentage>'",
		inherits: "false",
		initialValue: "0%",
	},
	"&::before": {
		position: "absolute",
		content: '" "',
		display: "block",
		inset: 0,
		backgroundImage: `conic-gradient(from 0deg, red 0%, red var(--itmc-capture-rotation-button), ${palette.Secondary} var(--itmc-capture-rotation-button), ${palette.Secondary} 100%)`,
		borderRadius: "50%",
		animation: recording ? `${maxRecordTime}s linear 0s backwards ${recordSpin}` : undefined,
		clipPath: "path('M 0 36 A 36 36 0 0 1 72 36 A 36 36 0 0 1 0 36 L 3 36 A 33 33 0 0 0 69 36 A 33 33 0 0 0 3 36 Z')",
	},
	"&::after": {
		position: "absolute",
		content: '" "',
		display: "block",
		top: "6px",
		right: "6px",
		bottom: "6px",
		left: "6px",
		borderRadius: "50%",
		backgroundColor: captureType === "video" ? palette.Primary : palette.Secondary,
	},
}));

type CaptureButtonProps = {
	recording: boolean;
	captureType: ITMCCaptureType;
	onClick: () => void;
	sx?: SxProps<Theme>;
};

export default function CaptureButton({ recording, captureType, onClick, sx }: CaptureButtonProps) {
	const { t } = useTranslation("common");

	useTimeout(onClick, recording ? maxRecordTime * 1000 : null);

	return (
		<Button
			type="button"
			captureType={captureType}
			recording={recording}
			onClick={onClick}
			sx={sx}
			aria-label={
				captureType === "camera"
					? t("media_capture.take_photo_button")
					: recording
						? t("media_capture.stop")
						: t("media_capture.record")
			}
		/>
	);
}
