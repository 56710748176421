import { Button, LinkButton } from "@components/Button";
import Pane from "@components/Pane";
import PaneHeading from "@components/Pane/PaneHeading";
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon";
import useIdify from "@hooks/useIdify";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { UserMediaErrorMap } from "./mediaDevices";

interface MediaErrorProps {
	error: DOMException | TypeError | Error;
	onGoBack: () => void;
}

type UserMediaError = keyof typeof UserMediaErrorMap;

export default function MediaErrors({ error, onGoBack }: MediaErrorProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const titleId = idify("media_error_title");
	const locale = useLocale();
	const externalLink = getSupportLinks("camera permissions", locale);

	const subtitle = useMemo(() => {
		if (error.name in UserMediaErrorMap) {
			return UserMediaErrorMap[error.name as UserMediaError];
		}
		return "unknown_error";
	}, [error]);

	const title = subtitle === "unknown_error" ? t("error_title") : t("media_capture.camera_error");

	return (
		<Pane>
			<PaneHeading title={title} titleId={titleId} />
			<p>{t(subtitle)}</p>
			<Box sx={{ display: "flex", gap: "4px" }}>
				<Button variant="primary ghost" size="lg" onClick={onGoBack} sx={{ flex: "1 0 0" }}>
					{t("in_the_moment.go_back_button")}
				</Button>
				<LinkButton size="lg" variant="primary" to={externalLink} sx={{ flex: "1 0 0" }}>
					{t("in_the_moment.visit_faq_button")} <ArrowUpRightIcon />
				</LinkButton>
			</Box>
		</Pane>
	);
}
