import { DEFAULT_LOCATION, USER_TESTING_LOCATION } from "@feature/location/constants";
import useIsAnon from "@hooks/useIsAnon";
import useLocation from "@hooks/useLocation";
import useUser from "@hooks/useUser";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import { Location } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import { useEffect, useMemo } from "react";
import useReportLocation from "./useReportLocation";

export default function useMapLocation(): [Location, boolean] {
	const [locationStatus, position, requestLocation] = useLocation();
	const isAnon = useIsAnon();
	const user = useUser();
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		if (locationStatus === "requestView" && user != null) {
			requestLocation();
			track("location_requested", { isAnon });
		}
	}, [locationStatus, isAnon, requestLocation, user]);

	const [location, source]: [Location, "ip" | "precise" | "default"] = useMemo(() => {
		if (featureFlags?.usertesting_subject === true) {
			return [USER_TESTING_LOCATION, "default"];
		}

		if (position?.coords.latitude != null && position?.coords.longitude != null) {
			return [
				{
					lat: position?.coords.latitude,
					lng: position?.coords.longitude,
				},
				"precise",
			];
		}

		if (featureFlags?.ip_geolocated_location != null) {
			return [featureFlags?.ip_geolocated_location, "ip"];
		}

		return [DEFAULT_LOCATION, "default"];
	}, [
		position?.coords.latitude,
		position?.coords.longitude,
		featureFlags?.ip_geolocated_location,
		featureFlags?.usertesting_subject,
	]);

	useReportLocation(user != null ? location : null, source);
	return [location, locationStatus === "resolvingGrant"];
}
