import useNavModalState from "@hooks/useNavModalState";
import useObservable from "@hooks/useObservable";
import { useEffect, useRef } from "react";
import ITMCDialog from "./ITMCDialog";
import ITMCHeader from "./ITMCHeader";
import { viewObservable } from "./itmcPostModel";
import PostCloseConfirmation from "./PostCloseConfirmation";
import { closeAction } from "./postModelAction/closeAction";
import ITMCMediaRecorder from "./stages/ITMCMediaRecorder";
import ITMCPhotoCrop from "./stages/ITMCPhotoCrop";
import ITMCPreviewUpdate from "./stages/ITMCPreviewUpdate";
import ITMCTextEntry from "./stages/ITMCTextEntry";

export default function ITMC() {
	const { open: navModalOpen, setOpen: setNavModalOpen } = useNavModalState("update");
	const view = useObservable(viewObservable);
	const viewIsOpen = !["closed", "saving"].includes(view ?? "closed");

	const previous = useRef<{ viewIsOpen: boolean; navModalOpen: boolean }>({ viewIsOpen, navModalOpen });
	useEffect(() => {
		const modelUpdatedOpen = viewIsOpen !== previous.current.viewIsOpen;
		if (navModalOpen === false && viewIsOpen && modelUpdatedOpen === false) {
			closeAction("override");
			return;
		}
		if (modelUpdatedOpen) {
			setNavModalOpen(viewIsOpen);
		}
	}, [navModalOpen, viewIsOpen, setNavModalOpen]);

	useEffect(() => {
		previous.current = { viewIsOpen, navModalOpen };
	}, [viewIsOpen, navModalOpen]);

	return (
		<ITMCDialog open={viewIsOpen} renderHeader={(titleId) => <ITMCHeader titleId={titleId} />}>
			{(() => {
				if (view === "text input") {
					return <ITMCTextEntry />;
				}
				if (view === "media recorder") {
					return <ITMCMediaRecorder />;
				}
				if (view === "photo crop") {
					return <ITMCPhotoCrop />;
				}
				if (view === "ready to save media") {
					return <ITMCPreviewUpdate />;
				}
			})()}
			<PostCloseConfirmation />
		</ITMCDialog>
	);
}
