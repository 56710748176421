import { SquareImageElement } from "@feature/itmc/components/ITMCMediaElement";
import LoopedVideo from "@feature/itmc/components/LoopedVideo";
import { nextAction } from "@feature/itmc/postModelAction/nextAction";
import { ITMCCaptureType, Media, PhotoMedia, VideoMedia } from "@feature/itmc/types";
import useObjectUrl from "@hooks/useObjectUrl";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

export const RECENT_MEDIA_SIZE = "60px";
export const RECENT_FONT_SIZE = "10px";
export const RECENT_LABEL_HEIGHT = "14px";

type RecentMediaButtonProps = {
	media: Media | null;
	captureType: ITMCCaptureType;
};

export default function RecentMediaButton({ media, captureType }: RecentMediaButtonProps) {
	const { t } = useTranslation("common");
	const mediaUrl = useObjectUrl(media?.blob);

	const hideRecent =
		media?.blob == null ||
		mediaUrl == null ||
		(media instanceof PhotoMedia && captureType === "video") ||
		(media instanceof VideoMedia && captureType === "camera");

	return (
		<>
			<Box
				component={hideRecent ? "div" : "button"}
				aria-label={hideRecent ? undefined : t("media_capture.recent")}
				sx={{
					width: RECENT_MEDIA_SIZE,
					height: RECENT_MEDIA_SIZE,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					background: "transparent",
					border: "none",
					padding: 0,
					position: "relative",
					...(hideRecent
						? undefined
						: {
								"&:before": {
									content: "attr(aria-label)",
									fontFamily: vcrOsdMono.style.fontFamily,
									color: palette.onSurface,
									fontSize: RECENT_FONT_SIZE,
									position: "absolute",
									bottom: "100%",
									backgroundColor: palette.Scrim,
									width: "100%",
									lineHeight: RECENT_LABEL_HEIGHT,
								},
							}),
				}}
				onClick={
					hideRecent
						? undefined
						: () => {
								nextAction();
							}
				}
			>
				{hideRecent ? null : media instanceof PhotoMedia ? (
					<SquareImageElement src={mediaUrl} alt={t("media_capture.recent")} sx={{ objectFit: "cover" }} />
				) : (
					<LoopedVideo videoUrl={mediaUrl} />
				)}
			</Box>
		</>
	);
}
