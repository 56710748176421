import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { State } from "../types";
import updateState from "./util/updateState";

export const [replaceMediaAction, replaceMediaActionSubject] = makeAction<void>();

export const replaceMediaActionObservable = replaceMediaActionSubject.pipe(
	updateState((_, state: State) => {
		if (state.view === "ready to save media" && state.text !== "") {
			return { ...state, view: "media recorder" };
		}
		reportError(new Error(`Tried to use ITMC replace action from invalid state! ${JSON.stringify(state)}`));
		return state;
	}),
);
