import { Button } from "@components/Button";
import PlusSquareIcon from "@components/icons/PlusSquareIcon";
import ITMCStageBody from "@feature/itmc/components/ITMCStageBody";
import ITMCUpdateMedia from "@feature/itmc/components/ITMCUpdateMedia";
import { mediaObservable, textObservable } from "@feature/itmc/itmcPostModel";
import { nextAction } from "@feature/itmc/postModelAction/nextAction";
import { replaceMediaAction } from "@feature/itmc/postModelAction/replaceMediaAction";
import { saveAction } from "@feature/itmc/postModelAction/saveAction";
import { PhotoMedia, VideoMedia } from "@feature/itmc/types";
import useObjectUrl from "@hooks/useObjectUrl";
import useObservable from "@hooks/useObservable";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import SquareBlock from "../../components/SquareBlock";
import CroppedPhoto from "./CroppedPhoto";

// Tested functionally in lib/feature/itmc/index.test.tsx

export default function ITMCPreviewUpdate() {
	const { t } = useTranslation("common");
	const textStatus = useObservable(textObservable);
	const media = useObservable(mediaObservable);
	const mediaUrl = useObjectUrl(media?.blob);

	return (
		<ITMCStageBody
			sx={{
				gap: "8px",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "stretch",
				"> button": {
					flex: "0 0 auto",
				},
				overflow: "hidden",
			}}
		>
			<SquareBlock>
				{media instanceof PhotoMedia && mediaUrl != null && media.crop != null ? (
					<CroppedPhoto imageUrl={mediaUrl} crop={media.crop} />
				) : null}
				{media instanceof VideoMedia && mediaUrl != null ? (
					<ITMCUpdateMedia src={mediaUrl} itmcMediaType="video" />
				) : null}
			</SquareBlock>
			{textStatus === "" ? null : (
				<Box
					component="p"
					sx={{
						flex: "1 1 auto",
						display: "relative",
						justifyContent: "flex-start",
						fontSize: "18px",
						margin: 0,
						marginTop: media != null ? "24px" : 0,
					}}
				>
					{textStatus}
				</Box>
			)}

			<Button
				size="lg"
				variant="primary"
				sx={{ marginTop: "auto" }}
				onClick={() => {
					saveAction();
				}}
			>
				{t("in_the_moment.post_update")}
				<PlusSquareIcon />
			</Button>
			<Button
				size="lg"
				variant="secondary ghost"
				onClick={() => {
					if (textStatus === "") {
						nextAction();
						return;
					}
					replaceMediaAction();
				}}
			>
				{textStatus === "" ? t("in_the_moment.add_text") : t("in_the_moment.replace_photo_or_video_button")}
			</Button>
		</ITMCStageBody>
	);
}
