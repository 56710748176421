import { distinctUntilChanged, map, merge, Observable, ReplaySubject, scan, share, startWith } from "rxjs";
import { backActionObservable } from "./postModelAction/backAction";
import { closeActionObservable } from "./postModelAction/closeAction";
import { nextActionObservable } from "./postModelAction/nextAction";
import { openActionObservable } from "./postModelAction/openAction";
import { replaceMediaActionObservable } from "./postModelAction/replaceMediaAction";
import { saveActionObservable } from "./postModelAction/saveAction";
import { cropActionObservable } from "./postModelAction/setCropAction";
import { photoMediaActionObservable } from "./postModelAction/setPhotoAction";
import { textActionObservable } from "./postModelAction/setTextAction";
import { videoMediaActionObservable } from "./postModelAction/setVideoAction";
import { defaultState, State } from "./types";

export const itmcPostModelObservable = merge(
	openActionObservable,
	photoMediaActionObservable,
	cropActionObservable,
	videoMediaActionObservable,
	backActionObservable,
	closeActionObservable,
	nextActionObservable,
	textActionObservable,
	saveActionObservable,
	replaceMediaActionObservable,
)
	.pipe(scan<(current: State) => State, State, State>((current, update) => update(current), defaultState))
	.pipe(startWith(defaultState))
	.pipe(share({ connector: () => new ReplaySubject<State>(1) }));

// State slices

export const viewObservable: Observable<State["view"]> = itmcPostModelObservable
	.pipe(map((state) => state.view))
	.pipe(distinctUntilChanged());

export const confirmDialogObservable: Observable<State["confirmation"]> = itmcPostModelObservable
	.pipe(map((state) => state.confirmation))
	.pipe(distinctUntilChanged());

export const mediaObservable: Observable<State["media"]> = itmcPostModelObservable
	.pipe(map((state) => state.media))
	.pipe(distinctUntilChanged());

export const textObservable: Observable<State["text"]> = itmcPostModelObservable
	.pipe(map((state) => state.text))
	.pipe(distinctUntilChanged());
