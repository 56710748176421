import { styled } from "@mui/material";
import palette from "@styles/palette";

const mediaStyles = {
	display: "block",
	width: "100%",
	aspectRatio: "1 / 1",
	border: `solid 1px ${palette.BKDRCoreBrandWhite}`,
};

export const SquareVideoElement = styled("video")(mediaStyles);
export const SquareImageElement = styled("img")(mediaStyles);
