import { Crop } from "@feature/itmc/types";
import assertType from "@lib/util/assertType";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useLayoutEffect, useMemo, useRef, useState } from "react";

type CroppedPhotoProps = {
	crop: Crop;
	imageUrl: string;
};

export default function CroppedPhoto({ crop, imageUrl }: CroppedPhotoProps) {
	const { t } = useTranslation("common");
	const containerRef = useRef<HTMLDivElement>(null);
	const [imageEl, setImageEl] = useState<HTMLImageElement | null>(null);
	const [containerWidth, setContainerWidth] = useState<number>();

	useLayoutEffect(() => {
		const containerEl = assertType(containerRef.current);

		setContainerWidth(containerEl.getBoundingClientRect().width);

		const observer = new ResizeObserver((entries) => {
			const observerEntry = entries.at(0);
			if (observerEntry == null) {
				return;
			}
			setContainerWidth(observerEntry.contentBoxSize.at(0)?.inlineSize);
		});

		observer.observe(containerEl);

		return () => {
			observer.disconnect();
		};
	}, []);

	const imageStyles = useMemo(() => {
		if (imageEl == null || containerWidth == null) {
			return undefined;
		}
		const scale = containerWidth / crop.width;
		return {
			position: "relative" as const,
			width: scale * imageEl.naturalWidth,
			height: scale * imageEl.naturalHeight,
			left: scale * -crop.x,
			top: scale * -crop.y,
		};
	}, [imageEl, crop, containerWidth]);

	return (
		<Box
			ref={containerRef}
			sx={{
				aspectRatio: "1 / 1",
				width: "100%",
				overflow: "hidden",
			}}
		>
			<Box
				component="img"
				alt={t("in_the_moment.cropped_photo_preview_alt")}
				src={imageUrl}
				onLoad={(event) => {
					setImageEl(event.target as HTMLImageElement);
				}}
				style={{
					visibility: imageStyles == null ? "hidden" : "visible",
					...imageStyles,
				}}
			/>
		</Box>
	);
}
