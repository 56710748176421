import { UploadPhotoDataType } from "@lib/models";
import { Box } from "@mui/material";
import { useMemo, useState } from "react";
type CroppedProfilePhotoPreviewType = {
	cropData: UploadPhotoDataType | null;
	imageUrl?: string;
};

export default function CroppedProfilePhotoPreview({ cropData, imageUrl }: CroppedProfilePhotoPreviewType) {
	const [cropContainer, onRefChange] = useState<HTMLDivElement | null>();

	const imagePosition = useMemo(() => {
		if (imageUrl == null || cropData == null || cropContainer == null) {
			return;
		}
		const cropRatioWidth = cropContainer.clientWidth / cropData.width;
		const cropRatioHeight = cropContainer.clientHeight / cropData.height;
		const newImage = new Image();
		newImage.src = imageUrl;
		return {
			x: cropData.x * cropRatioWidth,
			y: cropData.y * cropRatioHeight,
			width: newImage.naturalWidth * cropRatioWidth,
			height: newImage.naturalHeight * cropRatioHeight,
		};
	}, [imageUrl, cropData, cropContainer]);

	return (
		<Box
			ref={onRefChange}
			sx={{
				position: "absolute",
				aspectRatio: "1 / 1",
				overflow: "hidden",
				height: "100%",
				left: "50%",
				minWidth: 0,
				transform: "translate(-50%, 0)",
				"@media (max-aspect-ratio: 1/1)": {
					// unset height, left, and minWidth when in landscape
					height: "auto",
					left: "unset",
					minWidth: "auto",
					width: "100%",
					top: "50%",
					minHeight: 0,
					transform: "translate(0, -50%)",
				},
				backgroundImage: imageUrl == null ? "unset" : `url(${imageUrl})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: imagePosition == null ? "unset" : `-${imagePosition.x}px -${imagePosition.y}px`,
				backgroundSize: imagePosition == null ? "inherit" : `${imagePosition.width}px ${imagePosition.height}px`,
			}}
		/>
	);
}
