import useWindowResize from "@hooks/useWindowResize";
import assertType from "@lib/util/assertType";
import { Box } from "@mui/material";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";

type SquareBlockProps = {
	children: ReactNode;
};

export default function SquareBlock({ children }: SquareBlockProps) {
	const windowSizeId = useWindowResize();
	const boxRef = useRef<HTMLDivElement>();
	const [width, setWidth] = useState<number>();
	const [height, setHeight] = useState<number>();

	useLayoutEffect(() => {
		if (windowSizeId != null) {
			const { width } = assertType(boxRef.current).getBoundingClientRect();
			setWidth(width);
		}
	}, [windowSizeId]);

	useLayoutEffect(() => {
		if (windowSizeId != null && width != null) {
			const { height } = assertType(boxRef.current).getBoundingClientRect();
			setHeight(height);
		}
	}, [width, windowSizeId]);

	return (
		<Box
			ref={boxRef}
			sx={{
				flex: "0 1 auto",
				overflow: "hidden",
				position: "relative",
			}}
			style={{
				flexBasis: width,
			}}
		>
			<Box
				sx={{
					position: "absolute",
					top: "0px",
					left: "50%",
					height,
					width: height,
					transform: "translate(-50%)",
					transition: "all .1s",
				}}
			>
				{children}
			</Box>
		</Box>
	);
}
