import checkForNotification from "./checkForNotification";
import isIOSNativeWrapper from "./isIOSNativeWrapper";

export default async function getNotificationPermission() {
	if (
		isIOSNativeWrapper() &&
		window.webkit != null &&
		window.webkit.messageHandlers.getPushNotificationsPermission != null
	) {
		return await window.webkit.messageHandlers.getPushNotificationsPermission.postMessage([]);
	} else if (checkForNotification()) {
		return Notification.permission;
	}

	return null;
}
