import { Button } from "@components/Button";
import useObjectUrl from "@hooks/useObjectUrl";
import useWindowResize from "@hooks/useWindowResize";
import { UploadPhotoDataType } from "@lib/models";
import assertType from "@lib/util/assertType";
import reportError from "@lib/util/reportError";
import safeSx from "@lib/util/safeSx";
import { Box, SxProps, Theme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CropImageChild from "./CropImageChild";

const MIN_IMAGE_SIZE = 400;
const BOTTOM_PADDING = 40;
const SIDE_PADDING = 20;
const MOBILE_HANDLE_SIZE = 44;
const ADDITIONAL_PADDING_FOR_HANDLE_SIZE = MOBILE_HANDLE_SIZE / 2 - SIDE_PADDING;

type CropperViewProps = {
	inputFile: File;
	onCropFinish: (uploadData: UploadPhotoDataType) => void;
	sx?: SxProps<Theme>;
};

export function adjustCrop(image: HTMLImageElement, crop: PixelCrop) {
	const { width, height } = image.getBoundingClientRect();
	const xScaler = image.naturalWidth / width;
	const yScaler = image.naturalHeight / height;
	// because images can be displayed slightly squished, we need to get
	// the smaller scaler to use. In one dimension a couple pixels may be cut
	// off, but the opposite solution would require calculating the aspect
	// ratio difference and modifying the crop view to use a non-square.
	const scaler = Math.min(xScaler, yScaler);

	const calculatedSize = Math.floor(crop.width * scaler);
	const adjustedSize = Math.max(Math.min(calculatedSize, image.naturalWidth, image.naturalHeight), 400);

	const finalCrop = {
		x: Math.floor(crop.x * scaler),
		y: Math.floor(crop.y * scaler),
		width: adjustedSize,
		height: adjustedSize,
	};

	if (finalCrop.x < 0) {
		finalCrop.x = 0;
	}
	if (finalCrop.x > image.naturalWidth - adjustedSize) {
		finalCrop.x = image.naturalWidth - adjustedSize;
	}
	if (finalCrop.y < 0) {
		finalCrop.y = 0;
	}
	if (finalCrop.y > image.naturalHeight - adjustedSize) {
		finalCrop.y = image.naturalHeight - adjustedSize;
	}
	return finalCrop;
}

export default function CropperView({ inputFile, onCropFinish, sx }: CropperViewProps) {
	const { t } = useTranslation("common");
	const spaceAvailableBoxRef = useRef<HTMLDivElement>(null);
	const [imageEl, setImageEl] = useState<HTMLImageElement | null>(null);

	const windowSizeId = useWindowResize();

	const [crop, setCrop] = useState<PixelCrop>();

	const [imageDimensions, minCrop] = useMemo<[{ width: number; height: number } | undefined, number | null]>(() => {
		if (imageEl == null || spaceAvailableBoxRef.current == null || windowSizeId == null) {
			return [undefined, null];
		}
		const spaceAvailableEl = spaceAvailableBoxRef.current;
		const boundBox = spaceAvailableEl.getBoundingClientRect();
		const boxAspect = boundBox.width / boundBox.height;
		const imageAspect = imageEl.naturalWidth / imageEl.naturalHeight;
		const thinnerImage = boxAspect > imageAspect;
		const imageDisplayWidth = thinnerImage ? boundBox.height * imageAspect : boundBox.width;
		const imageDisplayHeight = thinnerImage ? boundBox.height : boundBox.width / imageAspect;
		const minCrop = Math.ceil(
			(MIN_IMAGE_SIZE / imageEl[thinnerImage ? "naturalHeight" : "naturalWidth"]) *
				(thinnerImage ? imageDisplayHeight : imageDisplayWidth),
		);
		return [{ width: imageDisplayWidth, height: imageDisplayHeight }, minCrop];
	}, [imageEl, windowSizeId]);

	const makeMaxCrop = useCallback(() => {
		if (imageDimensions != null && imageEl != null && windowSizeId != null) {
			// we can't use imageDimensions here, because the browser may not render
			// the image to the exact size, and if it's at all smaller, the crop ends
			// up being larger than the image, leading to out of bound values.
			// we *do* still need to ensure that imageDimensions is *set* at this point,
			// because we need to have done a layout pass with it before we can get the
			// correct dimensions.
			// In the future, we could look at using resize observer instead of useWindowResize
			// if we notice performance needs help.
			const { width, height } = imageEl.getBoundingClientRect();
			const portraitImage = imageEl.naturalWidth / imageEl.naturalHeight < 1;
			if (portraitImage) {
				const offset = (height - width) / 2;
				return {
					unit: "px" as const,
					x: 0,
					y: offset,
					width: width,
					height: width,
				};
			} else {
				const offset = (width - height) / 2;
				return {
					unit: "px" as const,
					x: offset,
					y: 0,
					width: height,
					height: height,
				};
			}
		}
		throw new Error("Shouldn't call makeMaxCrop when needed values aren't available");
	}, [imageDimensions, imageEl, windowSizeId]);

	useEffect(() => {
		if (imageEl != null && windowSizeId != null) {
			setCrop(makeMaxCrop());
		}
	}, [imageEl, windowSizeId, makeMaxCrop]);

	const nextImageUrl = useObjectUrl(inputFile);

	function onCropClick() {
		const image = assertType(imageEl);
		const pixelCrop = assertType(crop);
		let adjustedCrop = adjustCrop(image, pixelCrop);
		if (adjustedCrop.width < 400 || adjustedCrop.height < 400) {
			adjustedCrop = adjustCrop(image, makeMaxCrop());
		}
		if (
			adjustedCrop.x < 0 ||
			adjustedCrop.y < 0 ||
			adjustedCrop.x + adjustedCrop.width > image.naturalWidth ||
			adjustedCrop.y + adjustedCrop.height > image.naturalHeight
		) {
			const boundingBox = image.getBoundingClientRect();
			const errorContext = {
				crop: {
					original: crop,
					adjustedCrop,
					endX: adjustedCrop.x + adjustedCrop.width,
					endY: adjustedCrop.y + adjustedCrop.height,
				},
				image: {
					naturalWidth: image.naturalWidth,
					naturalHeight: image.naturalHeight,
					boundingWidth: boundingBox.width,
					boundingHeight: boundingBox.height,
				},
				windowScreen: {
					width: window.screen.width,
					height: window.screen.height,
				},
				maxCrop: makeMaxCrop(),
			};
			const cropOutOfBounds = new Error(`Crop out of bounds! ${JSON.stringify(errorContext, null, 2)}`);
			reportError(cropOutOfBounds);
		}
		onCropFinish({
			file: inputFile,
			...adjustedCrop,
		});
	}

	return (
		<Box
			sx={safeSx(
				{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					padding: `${SIDE_PADDING}px ${SIDE_PADDING}px ${BOTTOM_PADDING}px`,
					gap: `${MOBILE_HANDLE_SIZE / 2}px`,
					overflow: "hidden",
				},
				sx,
			)}
			data-testid="CropperView"
		>
			<Box
				ref={spaceAvailableBoxRef}
				sx={{
					flex: "1 1 0px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					mx: `${ADDITIONAL_PADDING_FOR_HANDLE_SIZE}px`,
					"--rc-drag-handle-mobile-size": `${MOBILE_HANDLE_SIZE}px`,
					overflow: "hidden",
				}}
			>
				<ReactCrop
					crop={crop}
					onChange={setCrop}
					aspect={1}
					minWidth={minCrop ?? undefined}
					minHeight={minCrop ?? undefined}
				>
					<CropImageChild
						style={imageDimensions}
						onLoad={(event) => {
							setImageEl(event.target as HTMLImageElement);
						}}
						alt={t("photo_input.crop_preview_photo_alt")}
						src={nextImageUrl}
					/>
				</ReactCrop>
			</Box>
			<Button variant="secondary" size="lg" onClick={onCropClick} sx={{ flex: "0 0 auto" }}>
				{t("next_button")}
			</Button>
		</Box>
	);
}
