import { RefObject, useLayoutEffect, useState } from "react";

export default function useInViewport(elementRef: RefObject<HTMLElement>) {
	const [isInViewport, setIsInViewport] = useState(false);

	useLayoutEffect(() => {
		if (elementRef.current == null) {
			return;
		}

		const observer = new IntersectionObserver((entries) => {
			const observerEntry = entries.at(0);
			if (observerEntry == null) {
				return;
			}
			setIsInViewport(observerEntry.isIntersecting);
		});

		observer.observe(elementRef.current);

		return () => {
			observer.disconnect();
		};
	}, [elementRef]);

	return isInViewport;
}
