import { Button } from "@components/Button";
import ArrowRightIcon from "@components/icons/ArrowRightIcon";
import PlusSquareIcon from "@components/icons/PlusSquareIcon";
import useIdify from "@hooks/useIdify";
import useObservable from "@hooks/useObservable";
import { Box, FormHelperText, TextField } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useMemo, useRef, useState } from "react";
import { mediaObservable, textObservable } from "../itmcPostModel";
import { nextAction } from "../postModelAction/nextAction";
import { saveAction } from "../postModelAction/saveAction";
import { setTextAction } from "../postModelAction/setTextAction";
import useVisualViewportHeight from "./useVisualViewportHeight";

const CHAR_LIMIT = 200;
const DEFAULT_BOTTOM_MARGIN = 40;
const OPEN_KEYBOARD_BOTTOM_MARGIN = 8;

function getCombinedHeight(...elements: (HTMLElement | null)[]) {
	return elements
		.map((element) => {
			if (element == null) {
				return 0;
			}
			const { height } = element.getBoundingClientRect();
			return Math.floor(height);
		})
		.reduce((accumulator, current) => {
			return accumulator + current;
		}, 0);
}

export default function ITMCTextEntry() {
	const { t } = useTranslation("common");

	const [isFocused, setIsFocused] = useState(false);
	const wrapperRef = useRef<HTMLElement>(null);
	const formHelperRef = useRef<HTMLParagraphElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const visualViewportHeight = useVisualViewportHeight();

	const idify = useIdify();
	const inputId = idify("itmc_text_entry");

	const textInput = useObservable(textObservable) ?? "";
	const isDisabled = !textInput.trim() || textInput.length > CHAR_LIMIT;
	const isAddTextFlow = useObservable(mediaObservable) != null;

	const { maxRows, buttonBottomMargin }: { maxRows: number | undefined; buttonBottomMargin: string } = useMemo(() => {
		if (wrapperRef.current == null || visualViewportHeight == null) {
			return { maxRows: undefined, buttonBottomMargin: `${DEFAULT_BOTTOM_MARGIN}px` };
		}

		const { top: wrapperTop, height: wrapperHeight } = wrapperRef.current.getBoundingClientRect();
		const workingVizViewHeight = visualViewportHeight - wrapperTop;
		const remainingVizViewHeight = workingVizViewHeight - getCombinedHeight(buttonRef.current, formHelperRef.current);
		// getComputedStyle returns the line height in pixels and parseFloat can safely ignore the "px" suffix
		const lineHeight = Math.floor(parseFloat(window.getComputedStyle(wrapperRef.current).lineHeight));
		const newMaxRows = Math.floor(remainingVizViewHeight / lineHeight);

		const buttonBottomMargin =
			workingVizViewHeight == null || wrapperHeight == null
				? `${DEFAULT_BOTTOM_MARGIN}px`
				: workingVizViewHeight >= wrapperHeight
					? `${DEFAULT_BOTTOM_MARGIN}px`
					: `${wrapperHeight - workingVizViewHeight + OPEN_KEYBOARD_BOTTOM_MARGIN}px`;

		return { maxRows: newMaxRows > 0 ? newMaxRows : undefined, buttonBottomMargin };
	}, [visualViewportHeight]);

	return (
		<Box
			ref={wrapperRef}
			sx={{
				gap: "8px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "stretch",
				height: "100%",
				padding: "40px 16px 0 16px",
				lineHeight: "24px",
			}}
		>
			<Box
				sx={{
					display: "relative",
					justifyContent: "flex-start",
				}}
			>
				<TextField
					id={inputId}
					data-testid="ITMCTextEntry"
					placeholder={
						isFocused ? t("in_the_moment.text_input_focused_placeholder") : t("in_the_moment.text_input_placeholder")
					}
					onChange={(event) => {
						setTextAction(event.target.value);
					}}
					value={textInput}
					multiline
					maxRows={maxRows}
					slotProps={{
						input: {
							onFocus() {
								setIsFocused(true);
							},
							onBlur() {
								setIsFocused(false);
							},
							sx: { padding: 0, caretColor: palette.Primary },
						},
					}}
					sx={{
						backgroundColor: "transparent",
						border: "none",
						color: palette.onPrimary,
						width: "100%",
						padding: 0,
						fontSize: "18px",
						"& fieldset": {
							border: "none",
							padding: 0,
						},
						"::placeholder": {
							color: palette.OnSurfaceSecondary,
						},
					}}
				/>
				<FormHelperText
					ref={formHelperRef}
					sx={{
						color: textInput.length > CHAR_LIMIT ? palette.Primary : palette.OnSurfaceSecondary,
						fontSize: "10px",
						marginTop: "12px",
					}}
				>
					{t("in_the_moment.remaining_character_ratio", {
						remainingCharacterCount: textInput.length,
						totalCharactersAllowed: CHAR_LIMIT,
					})}
				</FormHelperText>
			</Box>
			<Button
				ref={buttonRef}
				size="lg"
				variant={isAddTextFlow ? "secondary" : "primary"}
				onClick={() => {
					if (isAddTextFlow) {
						nextAction();
						return;
					}
					saveAction();
				}}
				disabled={isDisabled}
				sx={{ mt: "8px", marginBottom: buttonBottomMargin }}
			>
				{isAddTextFlow ? (
					<>
						{t("next_button")}
						<ArrowRightIcon />
					</>
				) : (
					<>
						{t("in_the_moment.post_update")}
						<PlusSquareIcon />
					</>
				)}
			</Button>
		</Box>
	);
}
