import CropperView from "@components/CropperView";
import useObservable from "@hooks/useObservable";
import { omit } from "ramda";
import { useMemo } from "react";
import { mediaObservable } from "../itmcPostModel";
import { setCropAction } from "../postModelAction/setCropAction";

export default function ITMCPhotoCrop() {
	const media = useObservable(mediaObservable) ?? null;

	const file = useMemo(() => {
		if (media?.blob == null) {
			return null;
		}
		return new File([media.blob], "image.jpg");
	}, [media]);

	if (file == null) {
		return null;
	}

	return (
		<CropperView
			inputFile={file}
			onCropFinish={(crop) => {
				setCropAction(omit(["file"], crop));
			}}
			sx={{
				flex: "1",
			}}
		/>
	);
}
