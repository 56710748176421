import { createiOSPushSubscription } from "@api/users";
import { track } from "./trackAnalytics";

export default async function registerForiOSNativeWrapperPushNotifications() {
	if (
		window.webkit == null ||
		window.webkit.messageHandlers.requestPushNotificationsPermission == null ||
		window.webkit.messageHandlers.registerForPushNotifications == null
	) {
		return;
	}

	const permission = await window.webkit.messageHandlers.requestPushNotificationsPermission.postMessage([]);
	if (permission === "denied") {
		return;
	}

	track("notifications_enabled");

	try {
		const token = await window.webkit.messageHandlers.registerForPushNotifications.postMessage([]);
		createiOSPushSubscription({ token });
	} catch (error) {
		track("notification_registration_failed", { ios_native_wrapper_error: error });
	}
}
