import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import { PANE_VERTICAL_SPACING } from "@components/Pane/PaneSection";
import { locationObservable } from "@feature/location/locationModel";
import useIdify from "@hooks/useIdify";
import useObservable from "@hooks/useObservable";
import useUser from "@hooks/useUser";
import { Location } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import { Box, DialogActions, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { LngLat } from "mapbox-gl";
import { useTranslation } from "next-i18next";
import { useMemo, useRef, useState } from "react";
import { getMap } from "../map/mapbox";
import mapConfig from "../map/mapbox/mapConfig";
import { exploreLocationOverrideBehavior, setExploreLocationOverride } from "./exploreOverrideModel";
import popularCities from "./popularCities";

const MIN_DISTANCE_FROM_LOCATION = 100; //in meters

type CityBase = { cityKey: string; coordinates: LngLat };

function locationEqual(location1: Location, location2: Location): boolean {
	return location1.lng === location2.lng && location1.lat === location2.lat;
}

type ExploreModeProps = {
	open: boolean;
	onClose: () => void;
};

export default function ExploreMode({ open, onClose }: ExploreModeProps) {
	const { t } = useTranslation("common");
	const ownUser = useUser();
	const isAdmin = ownUser?.is_admin === true;
	const selectRef = useRef<HTMLSelectElement>();
	const exploreLocationOverride = useObservable(exploreLocationOverrideBehavior);
	const [selectValue, setSelectValue] = useState(() => {
		if (!isAdmin || exploreLocationOverride == null) {
			return "my_neighborhood";
		}
		return (
			popularCities.find(({ coordinates }) => {
				return coordinates.lng === exploreLocationOverride.lng && coordinates.lat === exploreLocationOverride.lat;
			})?.cityKey ?? "my_neighborhood"
		);
	});
	const idify = useIdify();
	const selectId = idify("select-id");
	const labelId = idify("label-id");
	const mapbox = getMap();
	const ownLocation = useObservable(locationObservable);

	const cityMap = useMemo(() => {
		const localMap = new Map<string, CityBase>(popularCities.map((city) => [city.cityKey, city]));
		if (ownLocation != null) {
			localMap.set("my_neighborhood", {
				cityKey: "my_neighborhood",
				coordinates: new LngLat(ownLocation.lng, ownLocation.lat),
			});
		}
		return localMap;
	}, [ownLocation]);

	const cityCoords = cityMap.get(selectValue)?.coordinates;

	const goButtonDisabled =
		ownLocation == null ||
		cityCoords == null ||
		mapbox == null ||
		(isAdmin
			? locationEqual(cityCoords, exploreLocationOverride ?? ownLocation)
			: cityCoords.distanceTo(mapbox.getCenter()) < MIN_DISTANCE_FROM_LOCATION);

	function onSubmit() {
		if (goButtonDisabled) {
			return;
		}
		onClose();
		track("explore_mode_go_button_tapped", { cityKey: selectValue });
		mapbox.setCenter(cityCoords);
		mapbox.setZoom(mapConfig.zoom ?? 12);

		if (isAdmin) {
			if (selectValue === "my_neighborhood") {
				setExploreLocationOverride(null);
				return;
			}
			setExploreLocationOverride({ lng: cityCoords.lng, lat: cityCoords.lat });
		}
	}

	function onChange(e: SelectChangeEvent<string>) {
		const cityKey = e.target.value;
		setSelectValue(cityKey);
		track("explore_mode_option_selected", { cityKey });
	}

	function handleClose() {
		track("explore_mode_exited");
		onClose();
	}

	if (ownUser == null) {
		return null;
	}

	return (
		<Dialog
			slotProps={{
				backdrop: {
					sx: {
						backdropFilter: "none",
						backgroundColor: "transparent",
					},
				},
			}}
			dialogContainerProps={{
				sx: {
					alignItems: "flex-start",
					paddingTop: "56px",
				},
			}}
			open={open}
			onClose={handleClose}
			title={t("explore_popular_cities.explore_popular_cities_heading")}
		>
			<Box
				component="form"
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: PANE_VERTICAL_SPACING,
				}}
			>
				<FormControl fullWidth>
					<InputLabel id={labelId}>{t("explore_popular_cities.location_label")}</InputLabel>
					<Select
						ref={selectRef}
						labelId={labelId}
						id={selectId}
						label={t("explore_popular_cities.location_label")}
						onChange={onChange}
						value={selectValue}
					>
						<MenuItem key={"my_neighborhood"} value={"my_neighborhood"}>
							{t("explore_popular_cities.my_neighborhood")}
						</MenuItem>
						{popularCities
							.map((city) => ({ ...city, label: t(`explore_popular_cities.${city.cityKey}`) }))
							.sort((a, b) => a.label.localeCompare(b.label))
							.map(({ cityKey, label }) => (
								<MenuItem key={cityKey} value={cityKey}>
									{label}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<DialogActions>
					<Button variant="primary" disabled={goButtonDisabled} type="submit">
						{t("explore_popular_cities.lets_go_button")}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}
