import { MediaType } from "@lib/models";
import { useTranslation } from "next-i18next";
import { SquareImageElement } from "./ITMCMediaElement";
import LoopedVideo from "./LoopedVideo";

export type ITMCUpdateMediaProps = {
	src: string;
	itmcMediaType: Omit<MediaType, "text">;
};

export default function ITMCUpdateMedia({ src, itmcMediaType }: ITMCUpdateMediaProps) {
	const { t } = useTranslation("common");

	return itmcMediaType === "image" ? (
		<SquareImageElement alt={t("in_the_moment.current_photo_alt")} src={src} />
	) : (
		<>
			<LoopedVideo videoUrl={src} />
			<p className="sr-only">{t("in_the_moment.current_video_alt")}</p>
		</>
	);
}
