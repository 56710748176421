import { Button } from "@components/Button";
import CameraIcon from "@components/icons/CameraIcon";
import TextIcon from "@components/icons/TextIcon";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import { openItmc } from "../postModelAction/openAction";

type ITMCEntryOptionsProps = {
	onClose: () => void;
	optionsPadding: string;
};

export default function ITMCEntryOptions({ onClose, optionsPadding }: ITMCEntryOptionsProps) {
	const { t } = useTranslation("common");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-end",
				padding: optionsPadding,
				gap: "12px",
			}}
		>
			<Button
				variant="quaternary"
				onClick={() => {
					onClose();
					openItmc("media");
				}}
			>
				<CameraIcon />
				{t("in_the_moment.photo_or_video_button")}
			</Button>
			<Button
				variant="quaternary"
				onClick={() => {
					onClose();
					openItmc("text");
				}}
			>
				<TextIcon />
				{t("in_the_moment.text_status_button")}
			</Button>
		</Box>
	);
}
