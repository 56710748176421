import { Button } from "@components/Button";
import CameraIcon from "@components/icons/CameraIcon";
import RefreshIcon from "@components/icons/RefreshIcon";
import VideoIcon from "@components/icons/VideoIcon";
import { ITMCCaptureType, Media } from "@feature/itmc/types";
import useIdify from "@hooks/useIdify";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import CaptureButton from "./CaptureButton";
import RecentMediaButton from "./RecentMediaButton";

type ControlsProps = {
	captureType: ITMCCaptureType;
	onCaptureClick: () => void;
	onCaptureTypeChange: (captureType: ITMCCaptureType) => void;
	onDirectionClick: () => void;
	recording: boolean;
	recentMedia: Media | null;
};

export default function Controls({
	captureType,
	onCaptureClick,
	onCaptureTypeChange,
	onDirectionClick,
	recording,
	recentMedia,
}: ControlsProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const captureTypeDescriptionId = idify("capture-type-description");

	return (
		<>
			<Box
				sx={{
					zIndex: 0,
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Box
					sx={{
						zIndex: 0,
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
					}}
				>
					<p id={captureTypeDescriptionId} className="sr-only">
						{t("media_capture.media_type_buttons_screen_reader_helper")}
					</p>
					<Button
						icon
						variant="tertiary"
						size="md"
						aria-pressed={captureType === "video"}
						active={captureType === "video"}
						aria-describedby={captureTypeDescriptionId}
						aria-label={t("media_capture.video_button")}
						onClick={() => {
							onCaptureTypeChange("video");
						}}
					>
						<VideoIcon />
					</Button>
					<Button
						icon
						variant="tertiary"
						size="md"
						aria-pressed={captureType === "camera"}
						active={captureType === "camera"}
						aria-describedby={captureTypeDescriptionId}
						aria-label={t("media_capture.camera_button")}
						onClick={() => {
							onCaptureTypeChange("camera");
						}}
					>
						<CameraIcon />
					</Button>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
				<RecentMediaButton media={recentMedia} captureType={captureType} />
				<CaptureButton recording={recording} captureType={captureType} onClick={onCaptureClick} />
				<Button
					icon
					variant="secondary ghost"
					size="md"
					aria-label={t("media_capture.flip_camera")}
					onClick={onDirectionClick}
				>
					<RefreshIcon />
				</Button>
			</Box>
		</>
	);
}
