export type Crop = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export class Media {
	blob: Blob;
	constructor(blob: Blob) {
		this.blob = blob;
	}
}
export class PhotoMedia extends Media {
	crop: Crop | null;
	constructor({ blob, crop }: { blob: Blob; crop?: Crop | null }) {
		super(blob);
		this.crop = crop ?? null;
	}
	setCrop(crop: Crop) {
		return new PhotoMedia({ ...this, crop });
	}
}
export class VideoMedia extends Media {}

export type View = "closed" | "media recorder" | "photo crop" | "ready to save media" | "text input" | "saving";

export type ITMCCaptureType = "video" | "camera";

export type State = {
	view: View;
	media: null | Media;
	text: string;
	confirmation: false | "close" | "back";
};

export const defaultState: State = {
	view: "closed",
	media: null,
	text: "",
	confirmation: false,
};
