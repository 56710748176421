import CircleArrowNextButton from "@components/CircleArrowNextButton";
import CropperView from "@components/CropperView";
import HeaderBar from "@components/HeaderBar";
import useObjectUrl from "@hooks/useObjectUrl";
import { UploadPhotoDataType } from "@lib/models";
import { Box, Dialog } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import CroppedProfilePhotoPreview from "./CroppedProfilePhotoPreview";
import SaveView from "./SaveView";

const STEP_ACCEPT_IMAGE = "accept image";
const STEP_CROP_IMAGE = "crop image";
const STEP_SAVE_IMAGE = "save image";

type WizardState =
	| {
			step: typeof STEP_ACCEPT_IMAGE | typeof STEP_CROP_IMAGE;
			cropData: null;
	  }
	| {
			step: typeof STEP_SAVE_IMAGE;
			cropData: UploadPhotoDataType;
	  };

const defaultWizardState: WizardState = { step: STEP_ACCEPT_IMAGE, cropData: null };

function useWizardState() {
	const [wizardState, setWizardState] = useState<WizardState>(defaultWizardState);

	return {
		wizardState,
		reset: useCallback(() => {
			setWizardState(defaultWizardState);
		}, []),
		acceptImage() {
			setWizardState({
				step: STEP_CROP_IMAGE,
				cropData: null,
			});
		},
		cropImage(cropData: UploadPhotoDataType) {
			setWizardState({
				step: STEP_SAVE_IMAGE,
				cropData: cropData,
			});
		},
		retryCrop() {
			setWizardState({
				step: STEP_CROP_IMAGE,
				cropData: null,
			});
		},
	};
}

type PhotoUploadWizardProps = {
	imageFile: File | null;
	onClose: () => void;
	hasPinnedPhoto: boolean;
};

export default function PhotoUploadWizard({ imageFile, onClose, hasPinnedPhoto }: PhotoUploadWizardProps) {
	const { t } = useTranslation("common");
	const imageUrl = useObjectUrl(imageFile);
	const { wizardState, acceptImage, cropImage, retryCrop, reset } = useWizardState();

	const prevImagefileRef = useRef(imageFile);

	useEffect(() => {
		if (prevImagefileRef.current !== imageFile) {
			if (imageFile != null) {
				reset();
			}
			prevImagefileRef.current = imageFile;
		}
	}, [imageFile, reset]);

	return (
		<>
			<Dialog
				open={imageUrl != null}
				sx={{ backgroundColor: "common.black" }}
				PaperProps={{
					sx: {
						backgroundColor: "paper.background",
						backgroundImage: wizardState.step === STEP_ACCEPT_IMAGE ? `url(${imageUrl})` : "none",
						backgroundPosition: "center",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						maxHeight: "100%",
					},
				}}
				fullScreen
			>
				<HeaderBar
					onBack={wizardState.step === STEP_SAVE_IMAGE ? retryCrop : onClose}
					heading={wizardState.step === STEP_CROP_IMAGE ? t("profile.crop_step_heading") : undefined}
				/>
				{wizardState.step === STEP_SAVE_IMAGE && (
					<CroppedProfilePhotoPreview cropData={wizardState.cropData} imageUrl={imageUrl} />
				)}
				<Box
					sx={{
						flex: "0 1 100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "stretch",
						zIndex: 1,
					}}
				>
					{(() => {
						if (wizardState.step === STEP_ACCEPT_IMAGE) {
							return (
								<Box
									sx={{
										flex: "1 1 auto",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-end",
										alignItems: "center",
										paddingBottom: 2.5,
									}}
								>
									<Box
										sx={{
											backgroundColor: "rgba(0, 0, 0, 0.30)",
											width: "100%",
											padding: 1,
											display: "flex",
											justifyContent: "center",
										}}
									>
										<CircleArrowNextButton
											onClick={() => {
												acceptImage();
											}}
										/>
									</Box>
								</Box>
							);
						}
						if (wizardState.step === STEP_CROP_IMAGE) {
							if (imageFile == null) {
								return null;
							}
							return (
								<CropperView
									inputFile={imageFile}
									onCropFinish={(uploadData) => {
										cropImage(uploadData);
									}}
									sx={{
										flex: "1",
									}}
								/>
							);
						}
						if (wizardState.step === STEP_SAVE_IMAGE) {
							return <SaveView cropData={wizardState.cropData} onClose={onClose} hasPinnedPhoto={hasPinnedPhoto} />;
						}
					})()}
				</Box>
			</Dialog>
		</>
	);
}
