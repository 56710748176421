import GlobalFeed from "@feature/Feed/GlobalFeed";
import ITMC from "@feature/itmc";
import GlobalMap from "@feature/map/GlobalMap";
import { Outlet } from "react-router-dom";

export default function RootComponent() {
	return (
		<>
			<GlobalMap />
			<Outlet />
			<GlobalFeed />
			{/* TODO: replace with global modal component when adding more modals*/}
			<ITMC />
		</>
	);
}
