import useIdify from "@hooks/useIdify";
import { Box, Modal, styled } from "@mui/material";
import palette from "@styles/palette";
import { ReactNode } from "react";

const DialogContainer = styled("div")({
	position: "absolute",
	inset: 0,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	pointerEvents: "none",
});

type ITMCDialogProps = {
	open: boolean;
	onClose?: () => void;
	header?: ReactNode;
	children?: ReactNode;
	renderHeader: (titleId: string) => ReactNode;
};

export default function ITMCDialog({ children, open, onClose, renderHeader }: ITMCDialogProps) {
	const idify = useIdify();
	const titleId = idify("title");

	return (
		<Modal onClose={onClose} open={open}>
			<DialogContainer>
				<Box
					role="dialog"
					tabIndex={-1}
					aria-labelledby={titleId}
					aria-modal="true"
					sx={{
						width: "100%",
						height: "100%",
						maxWidth: "600px",
						backgroundColor: palette.surfaceContainer,
						position: "relative",
						display: "flex",
						flexDirection: "column",
						pointerEvents: "auto",
						gridTemplateRows: "auto 1fr",
						gridTemplateColumns: "100%",
						gridAutoFlow: "column",
						paddingTop: "var(--safe-top)",
					}}
				>
					{renderHeader(titleId)}
					<Box sx={{ overflow: "hidden", flex: 1, display: "flex", flexDirection: "column", position: "relative" }}>
						{children}
					</Box>
				</Box>
			</DialogContainer>
		</Modal>
	);
}
