export default async function imageValidator(file: File) {
	if (file.type !== "image/jpeg") {
		return [{ t: "photo_input.not_valid_filetype" }];
	}
	if (file.size > 10_000_000) {
		return [{ t: "photo_input.filesize_too_large" }];
	}
	const url = URL.createObjectURL(file);
	const [error, image] = await new Promise<[ErrorEvent | null, HTMLImageElement]>((resolve) => {
		const image = new Image();
		image.src = url;
		image.addEventListener("error", (error) => {
			resolve([error, image]);
		});
		image.addEventListener("load", () => {
			resolve([null, image]);
		});
	});
	const { naturalHeight, naturalWidth } = image;
	URL.revokeObjectURL(url);
	if (error != null) {
		return [{ t: "photo_input.file_invalid" }];
	}
	if (naturalHeight < 400 || naturalWidth < 400) {
		return [{ t: "photo_input.too_small" }];
	}
	return undefined;
}
