import { Button } from "@components/Button";
import { SizeVariant } from "@components/Button/variants";
import { Box, Modal } from "@mui/material";
import ITMCButton from "../ITMCButton";
import ITMCTutorialDialog from "../ITMCTutorialDialog";
import ITMCEntryOptions from "./ITMCEntryOptions";

export type ITMCButtonPosition = Pick<CSSStyleDeclaration, "bottom" | "right">;

type ITMCEntryPointProps = {
	buttonPosition: ITMCButtonPosition;
	buttonSize: SizeVariant;
	label: string;
	loading: boolean;
	onClose: () => void;
	optionsPadding: string;
	disableInfoButton?: boolean;
};

export default function ITMCEntry({
	buttonPosition,
	buttonSize,
	label,
	loading,
	onClose,
	optionsPadding,
	disableInfoButton,
}: ITMCEntryPointProps) {
	return (
		<>
			<Button variant="primary" icon aria-label="" disabled={true} size={buttonSize} />
			<Modal
				open={true}
				onClose={onClose}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-end",
					justifyContent: "flex-end",
				}}
			>
				<Box
					role="dialog"
					tabIndex={-1}
					aria-label={label}
					aria-modal="true"
					sx={{
						position: "absolute",
						transition: "bottom .1s, right .1s",
					}}
					style={buttonPosition}
				>
					<ITMCEntryOptions onClose={onClose} optionsPadding={optionsPadding} />
					<Box sx={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "flex-end" }}>
						<ITMCTutorialDialog disableInfoButton={disableInfoButton} />
						<ITMCButton open={true} label={label} loading={loading} onClick={onClose} size={buttonSize} />
					</Box>
				</Box>
			</Modal>
		</>
	);
}
