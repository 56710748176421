import { Button } from "@components/Button";
import { SizeVariant } from "@components/Button/variants";
import BoltIcon from "@components/icons/BoltIcon";
import XIcon from "@components/icons/XIcon";
import { CircularProgress, Tooltip } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

type ITMCButtonType = {
	loading: boolean;
	label: string;
	open: boolean;
	onClick: () => void;
	size?: SizeVariant;
};

export default function ITMCButton({ loading, label, open, onClick, size = "xl" }: ITMCButtonType) {
	const { t } = useTranslation("common");
	return (
		<Tooltip arrow placement="left" title={label}>
			<span>
				<Button
					onClick={onClick}
					variant="primary"
					size={size}
					aria-label={open ? t("close_button_label") : label}
					icon
					disabled={loading}
				>
					{loading ? (
						<CircularProgress size="small" />
					) : open ? (
						<XIcon sx={{ color: palette.Surface }} />
					) : (
						<BoltIcon />
					)}
				</Button>
			</span>
		</Tooltip>
	);
}
