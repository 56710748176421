import getNotificationPermission from "@lib/util/getNotificationPermission";
import isIOSNativeWrapper from "@lib/util/isIOSNativeWrapper";
import registerForiOSNativeWrapperPushNotifications from "@lib/util/registerForiOSNativeWrapperPushNotifications";
import registerForPushNotifications from "@lib/util/registerForPushNotifications";
import { useEffect } from "react";
import { filter, map, take } from "rxjs";
import { accountRegisterObservable } from "./accountRegisterModel";

async function register() {
	if ((await getNotificationPermission()) !== "granted") {
		return;
	}

	if (isIOSNativeWrapper()) {
		registerForiOSNativeWrapperPushNotifications();
	} else {
		registerForPushNotifications();
	}
}

function registerIfGranted() {
	const subscription = accountRegisterObservable
		.pipe(map((accountRegister) => accountRegister.user))
		.pipe(filter((user) => user != null))
		.pipe(take(1))
		.subscribe(register);

	return () => {
		subscription.unsubscribe();
	};
}

export default function StartupPushNotificationRegistration() {
	useEffect(registerIfGranted, []);

	return null;
}
