import { Button } from "@components/Button";
import { SizeVariant } from "@components/Button/variants";
import useInViewport from "@hooks/useInViewport";
import useUser from "@hooks/useUser";
import assertType from "@lib/util/assertType";
import { track } from "@lib/util/trackAnalytics";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { filter } from "rxjs";
import ITMCActiveUpdate from "../ActiveUpdate";
import ITMCButton from "../ITMCButton";
import { viewObservable } from "../itmcPostModel";
import { openItmc } from "../postModelAction/openAction";
import ITMCEntry from "./ITMCEntry";

type ITMCEntryControllerProps = {
	source: "map" | "feed" | "profile";
	buttonSize?: SizeVariant;
	optionsPadding?: string;
};

export default function ITMCEntryController({
	source,
	buttonSize = "xl",
	optionsPadding = "0 20px 20px 0",
}: ITMCEntryControllerProps) {
	const { t } = useTranslation("common");
	const [openDialog, setOpenDialog] = useState<"entry point" | "active update" | null>(null);
	const ownUser = useUser();
	const itmc = ownUser?.in_the_moment_status;
	const hasITMC = itmc != null;
	const isProcessing = hasITMC != null && itmc?.processing === true;
	const containerRef = useRef<null | HTMLElement>(null);
	const isInView = useInViewport(containerRef);

	const [buttonPosition, setButtonPosition] = useState<Pick<CSSStyleDeclaration, "bottom" | "right">>({
		bottom: "unset",
		right: "unset",
	});

	useEffect(() => {
		const subscription = viewObservable.pipe(filter((value) => value === "saving")).subscribe(() => {
			setOpenDialog(null);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const labelTitle = isProcessing
		? t("loading")
		: openDialog === "entry point"
			? t("close_button_label")
			: hasITMC
				? t("in_the_moment_view_button_label")
				: t("in_the_moment_button_label");

	const onOpen = useCallback(() => {
		track("itm_button_tapped", { source });
		if (hasITMC) {
			setOpenDialog("active update");
			return;
		}
		setOpenDialog("entry point");
	}, [source, hasITMC]);

	const onClose = useCallback(() => {
		track("itmc_closed", { source });
		setOpenDialog(null);
	}, [source]);

	useLayoutEffect(() => {
		if (!isInView) {
			return;
		}

		function setNewButtonPosition(positionRef: { bottom: number; right: number }, containerEl: HTMLElement) {
			const { bottom, right } = containerEl.getBoundingClientRect();
			setButtonPosition({ bottom: `${positionRef.bottom - bottom}px`, right: `${positionRef.right - right}px` });
		}

		const containerEl = assertType(containerRef.current);

		const observer = new ResizeObserver((entries) => {
			const observerEntry = entries.at(0);
			if (observerEntry == null) {
				return;
			}

			setNewButtonPosition(observerEntry.contentRect, containerEl);
		});

		observer.observe(document.documentElement);

		return () => {
			observer.disconnect();
		};
	}, [isInView]);

	return (
		<Box ref={containerRef}>
			{(() => {
				if (openDialog === "entry point") {
					return (
						<ITMCEntry
							buttonSize={buttonSize}
							onClose={onClose}
							label={labelTitle}
							loading={isProcessing}
							buttonPosition={buttonPosition}
							optionsPadding={optionsPadding}
							disableInfoButton={source === "profile"}
						/>
					);
				}
				if (hasITMC && openDialog === "active update") {
					return (
						<>
							<Button variant="primary" icon aria-label="" size={buttonSize} disabled={true} />
							<ITMCActiveUpdate
								open={true}
								itmc={itmc}
								onClose={() => setOpenDialog(null)}
								onReplace={() => {
									openItmc(itmc.media_type === "text" ? "text" : "media");
								}}
							/>
						</>
					);
				}
				return <ITMCButton open={false} label={labelTitle} loading={isProcessing} onClick={onOpen} size={buttonSize} />;
			})()}
		</Box>
	);
}
