// Tested in @feature/itmc/ITMCMediaRecorder/useMediaHandler.test.tsx
import { CameraDirection } from "@lib/models";

export type RequestedMediaStream = null | MediaStream | Error;

export const UserMediaErrorMap = {
	AbortError: "media_capture.failed_to_start_camera_error",
	NotAllowedError: "media_capture.permission_denied_error",
	NotFoundError: "media_capture.no_camera_error",
	NotReadableError: "media_capture.failed_to_start_camera_error",
	NotSupportedError: "media_capture.recording_error",
	TypeError: "media_capture.failed_to_start_camera_error",
} as const;

export async function requestUserMedia(cameraDirection: CameraDirection = "user", isSquare: boolean = true) {
	return navigator.mediaDevices.getUserMedia({
		// constraints - only video needed for this app
		video: {
			facingMode: cameraDirection,
			aspectRatio: isSquare ? 1 : 16 / 9,
			width: isSquare ? 720 : { ideal: 2560 },
			height: isSquare ? 720 : undefined,
		},
	});
}

export function makeMediaRecorder(
	stream: MediaStream,
	outputRef: { current: BlobEvent["data"][] },
	videoFinished: (blob: Blob) => void,
) {
	const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=vp8") ? "video/webm;codecs=vp8" : "video/mp4";

	const mediaRecorder = new MediaRecorder(stream, { mimeType });
	mediaRecorder.ondataavailable = (event) => {
		if (event.data.size > 0) {
			outputRef.current.push(event.data);
		}
	};

	mediaRecorder.onstop = () => {
		const blob = new Blob(outputRef.current, {
			type: mimeType,
		});
		videoFinished(blob);
	};
	return mediaRecorder;
}
