import ITMReply from "@feature/itmc/ITMReply";
import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import { AnyAccount } from "@lib/models";
import { Box } from "@mui/material";
import ITMCTimestampAndMessage from "./ITMCTimestampAndMessage";

type ITMCOverlayProps = {
	account: AnyAccount;
	showReplyButton: boolean;
	showTimestamp: boolean;
};

export default function ITMCOverlay({ account, showReplyButton, showTimestamp }: ITMCOverlayProps) {
	const itmContent = account.in_the_moment_status;

	if (itmContent == null) {
		return null;
	}

	return (
		<Box
			sx={(theme) => ({
				position: "absolute",
				bottom: 0,
				display: "grid",
				gridTemplateColumns: showReplyButton ? "1fr auto" : "1fr",
				gridTemplateRows: showTimestamp ? "auto auto" : "1fr",
				p: theme.spacing(1.5),
				width: "100%",
				alignItems: "end",
				columnGap: theme.spacing(1.5),
			})}
		>
			<ITMCTimestampAndMessage account={account} showTimestamp={showTimestamp} />
			{showReplyButton ? (
				<SnackbarProvider>
					<ITMReply profile={account} />
				</SnackbarProvider>
			) : null}
		</Box>
	);
}
