import ErrorPage from "@components/ErrorPage";
import reportError from "@lib/util/reportError";
import { useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function RootRouterError() {
	const error = useRouteError();

	useEffect(() => {
		const isRouteErrorResponseObject = isRouteErrorResponse(error);
		if (isRouteErrorResponseObject && error.status !== 404) {
			const stringifiedData = (() => {
				try {
					return JSON.stringify(error.data);
				} catch {
					return `Failed to stringify, properties: ${Object.keys(error.data)
						.map((key) => `"${key}"`)
						.join(", ")}`;
				}
			})();
			reportError(
				new Error(`Route Response Error, status: ${error.status} ${error.statusText}, data: ${stringifiedData}`),
			);
		} else if (!isRouteErrorResponseObject) {
			reportError(
				error instanceof Error
					? error
					: new Error(`Non-error value caught in RootRouterError, String(error): ${String(error)}`),
			);
		}
	}, [error]);

	if (isRouteErrorResponse(error) && error.status === 404) {
		return <ErrorPage error="404" />;
	}
	return <ErrorPage error="500" />;
}
