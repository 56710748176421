import reportError from "@lib/util/reportError";
import { RefObject } from "react";

class VideoCaptureError extends Error {
	constructor(message: string) {
		super(message);
		this.name = "VideoCaptureError";
	}
}

export async function captureVideoFrame(videoRef: RefObject<HTMLVideoElement>): Promise<Blob> {
	try {
		if (videoRef.current == null) {
			throw new VideoCaptureError("Video element is not available");
		}

		const video = videoRef.current;
		const canvas = document.createElement("canvas");
		const width = video.videoWidth;
		const height = video.videoHeight;
		canvas.width = width;
		canvas.height = height;

		const context = canvas.getContext("2d");
		if (context == null) {
			throw new VideoCaptureError("Failed to get canvas context");
		}

		context.drawImage(video, 0, 0, width, height);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (blob != null) {
					resolve(blob);
				} else {
					reject(new VideoCaptureError("Failed to create blob from canvas"));
				}
			}, "image/jpeg");
		});
	} catch (error) {
		reportError(error as VideoCaptureError);
		return Promise.reject(error);
	}
}
