import { LinkButton } from "@components/Button";
import ITMCEntryController from "@feature/itmc/entry/ITMCEntryController";
import useUser from "@hooks/useUser";
import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import Edit from "@mui/icons-material/Edit";
import { useTranslation } from "next-i18next";

export default function UserBottomActions() {
	const { t } = useTranslation("common");
	const user = useUser();

	return (
		<>
			{user?.is_anon ? null : (
				<LinkButton size="md" variant="secondary" aria-label={t("edit_button")} to="/account/profile">
					<Edit />
					{t("edit_button")}
				</LinkButton>
			)}
			<SnackbarProvider>
				<ITMCEntryController source="profile" buttonSize="md" optionsPadding="0 0 12px 0" />
			</SnackbarProvider>
		</>
	);
}
