import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { PhotoMedia } from "../types";
import updateState from "./util/updateState";

const [setPhotoAction, photoActionSubject] = makeAction<Blob>();

export { setPhotoAction };

export const photoMediaActionObservable = photoActionSubject.pipe(
	updateState((blob, state) => {
		if (state.view === "media recorder") {
			return {
				...state,
				media: new PhotoMedia({ blob }),
				view: "photo crop",
			};
		}
		reportError(new Error("Tried to set ITMC photo from wrong view!"));
		return state;
	}),
);
