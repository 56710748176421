import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { PhotoMedia } from "../types";
import updateState from "./util/updateState";

const [nextAction, nextActionSubject] = makeAction<void>();

export { nextAction };

export const nextActionObservable = nextActionSubject.pipe(
	updateState((_, state) => {
		if (state.confirmation) {
			reportError(new Error(`Tried to use ITMC next action while confirmation = true! ${JSON.stringify(state)}`));
			return state;
		}
		if (state.view === "media recorder" && state.media != null) {
			return {
				...state,
				view: state.media instanceof PhotoMedia ? "photo crop" : "ready to save media",
			};
		}
		if (state.view === "ready to save media" && state.text === "") {
			return {
				...state,
				view: "text input",
			};
		}
		if (state.view === "text input" && state.text !== "" && state.media != null) {
			return {
				...state,
				view: "ready to save media",
			};
		}
		reportError(new Error(`Tried to use ITMC next action from invalid state! ${JSON.stringify(state)}`));
		return state;
	}),
);
