import { EmailNotUnique, EmailNotValid, registerAnonAccount } from "@api/account";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import EmailField from "@components/EmailField";
import PasswordField from "@components/PasswordField";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Box, DialogActions } from "@mui/material";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { ReactNode, useState } from "react";
import { Form } from "react-final-form";
import { useSWRConfig } from "swr";
import useAlertState from "../alert/useAlertState";
import AnonFormLinks from "./AnonFormLinks";

type RegisterAnonFormValues = {
	email: string;
	password: string;
};

const focusOnError = createDecorator<RegisterAnonFormValues>();
const formDecorators = [focusOnError];

type RegisterAnonModalProps = {
	renderButton: (onOpen: () => void) => ReactNode;
};

export default function RegisterAnonModal({ renderButton }: RegisterAnonModalProps) {
	const { t } = useTranslation("common");

	const [open, setOpen] = useState(false);

	function onClose() {
		setOpen(false);
	}

	const { mutate } = useSWRConfig();
	const [alert, updateAlert] = useAlertState();

	const onSubmit = makeHandleFormSubmit<RegisterAnonFormValues>({
		submitter: async (values: RegisterAnonFormValues) => {
			await registerAnonAccount({
				login: values.email,
				password: values.password,
			});
			// mark all swr cached endpoints stale
			await mutate(() => true);
			await refreshOwnUser();
			track("anon_account_registered");
			onClose();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
		errorHandlers: [
			{
				errorType: EmailNotUnique,
				result: t("email.not_unique"),
			},
			{
				errorType: EmailNotValid,
				result: t("email.invalid_error"),
			},
		],
	});

	return (
		<>
			{renderButton(() => {
				setOpen(true);
			})}
			<Dialog
				title={t("anon_mode.account_registration_header")}
				subtitle={t("anon_mode.account_registration_body")}
				open={open}
				onClose={onClose}
			>
				<Form
					onSubmit={onSubmit}
					decorators={formDecorators}
					validate={(values) => (values.email == null ? { [FORM_ERROR]: true } : undefined)}
					render={({ handleSubmit, hasValidationErrors, submitting }) => {
						return (
							<Box
								component="form"
								onSubmit={handleSubmit}
								noValidate={true}
								sx={(theme) => ({
									my: "auto",
									display: "flex",
									flexDirection: "column",
									alignItems: "stretch",
									overflow: "visible",
									gap: theme.spacing(2),
								})}
							>
								<EmailField />
								<PasswordField />
								{alert == null ? null : <Alert key={alert.key} severity={alert.severity} body={alert.children} />}
								<DialogActions>
									<Button type="submit" disabled={submitting || hasValidationErrors} variant="primary">
										{t("register_account_button")}
									</Button>
								</DialogActions>
								<AnonFormLinks forgotPasswordLink />
							</Box>
						);
					}}
				/>
			</Dialog>
		</>
	);
}
