import { useEffect, useState } from "react";

export default function useObjectUrl(blob?: Blob | null): string | undefined {
	const [outputUrl, setOutputUrl] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (blob != null) {
			const url = URL.createObjectURL(blob);
			setOutputUrl(url);
			return () => {
				setOutputUrl(undefined);
				URL.revokeObjectURL(url);
			};
		}
	}, [blob]);

	return blob == null ? undefined : outputUrl;
}
