import HeaderBar from "@components/HeaderBar";
import useObservable from "@hooks/useObservable";
import { useTranslation } from "next-i18next";
import { mediaObservable, viewObservable } from "./itmcPostModel";
import { backAction } from "./postModelAction/backAction";
import { closeAction } from "./postModelAction/closeAction";

type ITMCHeaderProps = {
	titleId: string;
};

export default function ITMCHeader({ titleId }: ITMCHeaderProps) {
	const { t } = useTranslation("common");
	const view = useObservable(viewObservable);
	const recentMedia = useObservable(mediaObservable);

	const heading = (() => {
		if (view === "text input") {
			if (recentMedia == null) {
				return t("in_the_moment.text_status_button");
			}
			return t("in_the_moment.add_text");
		}
		if (view === "ready to save media") {
			return t("in_the_moment.preview_update_heading");
		}
		return "";
	})();

	return (
		<HeaderBar
			titleId={titleId}
			heading={heading}
			onBack={backAction}
			onClose={closeAction}
			sx={{ minHeight: "45px", padding: "0 8px", "[aria-label='BACK']": { marginLeft: 0 } }}
		/>
	);
}
