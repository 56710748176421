import useIdify from "@hooks/useIdify";
import imageValidator from "@lib/util/imageValidator";
import { useTranslation } from "next-i18next";
import { DragEvent, ReactNode } from "react";

type LabelProps = {
	htmlFor: string;
	onDrop: undefined | ((event: DragEvent<HTMLLabelElement>) => void);
	onDragOver: (event: DragEvent<HTMLLabelElement>) => void;
};

type ImageFileInputProps = {
	renderLabel: (labelProps: LabelProps) => ReactNode;
	inputLabelText: string;
	onFileSelected: (file: File) => void;
	onError: (errorMessage: string) => void;
	disabled?: boolean;
	isInTheMoment?: boolean;
	helperTextId?: string;
};

export default function ImageFileInput({
	renderLabel,
	inputLabelText,
	onFileSelected,
	onError,
	disabled,
	isInTheMoment = false,
	helperTextId,
}: ImageFileInputProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();

	const inputId = idify("input");

	async function onImageSelected(imageFile: File | null) {
		if (imageFile == null) {
			return;
		}
		const validationResult = await imageValidator(imageFile);
		if (validationResult === undefined) {
			onFileSelected(imageFile);
		} else {
			onError(validationResult.map(({ t: errorMessageKey }) => t(errorMessageKey)).join(" "));
		}
	}
	return (
		<>
			{renderLabel({
				htmlFor: inputId,
				onDrop: disabled
					? undefined
					: (event) => {
							event.preventDefault();
							if (event.dataTransfer.items != null) {
								const fileItem = Array.from(event.dataTransfer.items).at(0);
								const file = fileItem?.getAsFile();
								onImageSelected(file ?? null);
							} else {
								const file = Array.from(event.dataTransfer.files).at(0);
								onImageSelected(file ?? null);
							}
						},
				onDragOver: (event) => {
					event.preventDefault();
				},
			})}
			<input
				disabled={disabled}
				aria-label={inputLabelText}
				aria-describedby={helperTextId}
				className="sr-only"
				type="file"
				id={inputId}
				onChange={(event) => {
					onImageSelected(event.target.files?.[0] ?? null);
					event.target.value = "";
				}}
				accept="image/jpeg"
				capture={isInTheMoment ? "user" : undefined}
				aria-hidden="true"
			/>
		</>
	);
}
