import isIOSNativeWrapper from "@lib/util/isIOSNativeWrapper";
import registerForiOSNativeWrapperPushNotifications from "@lib/util/registerForiOSNativeWrapperPushNotifications";
import registerForPushNotifications from "@lib/util/registerForPushNotifications";
import { Button, ListItem } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function RegisterForPushNotificationsListItem() {
	const { t } = useTranslation("common");
	return (
		<ListItem>
			<Button
				variant="contained"
				onClick={isIOSNativeWrapper() ? registerForiOSNativeWrapperPushNotifications : registerForPushNotifications}
				fullWidth
			>
				{t("account_settings.allow_push_notifications_button")}
			</Button>
		</ListItem>
	);
}
