import { ITMCCaptureType } from "@feature/itmc/types";
import useAsyncInstance from "@hooks/useAsyncInstance";
import { CameraDirection } from "@lib/models";
import assertType from "@lib/util/assertType";
import { useCallback, useEffect, useRef, useState } from "react";
import { makeMediaRecorder, requestUserMedia } from "./mediaDevices";

export class MediaRecorderError extends Error {
	event?: Event;
}
export class MediaSetupError extends Error {}

export default function useMediaHandler(
	cameraDirection: CameraDirection,
	recording: boolean,
	onVideoFinished: (file: Blob) => void,
	onCanPlay: () => void,
	onError: (e: Error) => void,
	captureType: ITMCCaptureType,
) {
	const videoRef = useRef<HTMLVideoElement>(null);

	const [recordReadyObject, setRecordReadyObject] = useState<{
		mediaRecorder: MediaRecorder;
	} | null>(null);

	const outputRef = useRef<BlobEvent["data"][]>([]);

	const makeMediaInstance = useCallback(async () => {
		const stream = await requestUserMedia(cameraDirection, captureType === "video");
		const videoEl = assertType(videoRef.current);
		videoEl.srcObject = stream;
		videoEl.onloadedmetadata = () => {
			videoEl.play();
		};
		videoEl.oncanplay = onCanPlay;

		const mediaRecorder = makeMediaRecorder(stream, outputRef, onVideoFinished);
		setRecordReadyObject({
			mediaRecorder,
		});

		return async function cleanUp() {
			videoEl.srcObject = null;
			mediaRecorder.onstop = null;
			outputRef.current = [];
			mediaRecorder.stop();
			stream.getTracks().forEach((track) => {
				track.stop();
			});
			setRecordReadyObject(null);
		};
	}, [onVideoFinished, cameraDirection, onCanPlay, captureType]);

	const { error } = useAsyncInstance(makeMediaInstance);

	useEffect(() => {
		if (error != null) {
			onError(error);
		}
	}, [error, onError]);

	const prevRecording = useRef(false);
	useEffect(() => {
		if (recordReadyObject != null && recording) {
			prevRecording.current = recording;
			outputRef.current = [];
			recordReadyObject.mediaRecorder.start();
		} else if (recordReadyObject != null && !recording && prevRecording.current) {
			recordReadyObject.mediaRecorder.stop();
		}
	}, [recordReadyObject, recording]);
	return videoRef;
}
