import { InTheMomentStatus } from "@lib/models";
import ITMCUpdateMedia from "../components/ITMCUpdateMedia";
import ITMCProcessingMedia from "./ITMCProcessingMedia";

type ITMCAvitveUpdateMediaProps = { itmc: InTheMomentStatus };

export default function ITMCActiveUpdateMedia({ itmc }: ITMCAvitveUpdateMediaProps) {
	if (itmc.processing) {
		return <ITMCProcessingMedia mediaType={itmc.media_type} />;
	}

	const src =
		itmc.media_type === "image" && itmc.full != null
			? itmc.full
			: itmc.media_type === "video" && itmc.video != null
				? itmc.video
				: null;

	if (src == null) {
		return null;
	}

	return <ITMCUpdateMedia src={src} itmcMediaType={itmc.media_type} />;
}
